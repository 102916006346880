import {
    $, addAction, doAction, INIT, LAYOUT,
} from '@situation/setdesign.util';

const setupBlogCardListPagination = () => {
    $('[data-multi]').each((i, container) => {
        const $container = $(container);
        const paginationCount = $(container).data('multi');
        const $columns = $container.find(
            '.blog-card-list__item:not(.blog-card-list__item--featured)',
        );
        const $more = $container.find('.blog-card-list__more-btn');
        let currentIndex = 0;

        const showNextColumns = () => {
            let delay = 0;
            const $set = $columns
                .slice(currentIndex, currentIndex + paginationCount)
                .addClass('blog-card-list__item--load');
            doAction(LAYOUT);
            setTimeout(() => {
                $set.each((j, col) => {
                    $(col).css('transitionDelay', `${delay}s`);
                    delay += 0.15;
                }).addClass('blog-card-list__item--show');
            }, 100);

            currentIndex += paginationCount;

            if (currentIndex >= $columns.length) {
                $more.remove();
            }
        };

        showNextColumns();
        $more.on('click', showNextColumns);
    });
};

addAction(INIT, setupBlogCardListPagination);
